footer {
    margin: 5em auto 0;
    padding: 5vh 10vh;
    background-color: rgb(33, 33, 33);
    color:rgb(255, 255, 255)
}
.grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
}
footer h4 {
    margin-bottom: 2em;
}
footer article p {
    text-align: center;
    font-size: small;
    margin: 1em;
}
footer a:link,footer a:visited {
    color:white
}
footer a:active,footer a:hover {
color:lawngreen
}

