* {
  margin: 0;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  box-sizing: border-box;
}


/* global */
h1 {
  /*   font-family: 'Italiana', serif; */
  /* font-family: 'Libre Caslon Display', serif; */
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  margin: .5em 0;
}

h2,
h3,
h4,
h5,
h6 {
  /*   font-family: 'Italiana', serif; */
  /* font-family: 'Libre Caslon Display', serif; */
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  margin: .5em 0;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.4em;
}

p {
  /*   font-size: 1.2em; */
  margin-bottom: 2em;
}

section {
  margin: 5em auto;
}

main {
  width: 80%;
  max-width: 1200px;
  margin: 1em auto;
  padding: 1em;
}

ul {
  list-style: outside;
}

.thickText {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.thinText {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

span.emphasis {
  font-family: inherit;
  font-size: inherit;
  text-decoration: underline;
  text-decoration-thickness: from-font;
}

.leftAligned,
.leftAligned>li {
  text-align: left;
  margin-bottom: .8em;
}

/* layouts */
.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4em;
  column-gap: 2em;
}

.grid2 img {
  width: 75%;
}

.inlineFlexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5em auto;
}

.inlineFlexContainer a img.badge {
  margin-bottom: 1em;
}

section.textView {
  max-width: 800px;
}
section.textView * {
  text-align: left;
}
/* section.textView > * {
  margin-bottom: 1rem;
} */
section.textView  article {
  margin-bottom: 2rem;
}
section.textView p, section.textView li {
  line-height: 1.5em;
}

/* elements */

.badge {
  margin: 0 1em;
  height: 30px;
}

#webDashboardBadge {
  background: black;
  color: white;
  padding: 8px 5px;
  border-radius: 5px;
  display: inline-block;
  margin: 0 1em;
  font-weight: 700;
  font-size: 14px;
  height: 30px;
}

#parallelogram {
  width: fit-content;
  transform: skew(-30deg);
  background: red;
  padding: 1em 3em;
  margin: 1em auto;
}

.parallelogramSeparator {
  width: 2em;
  height: 1em;
  transform: skew(-30deg);
  background: #ff8282;
  margin: 3em auto;
}

#parallelogram * {
  transform: skew(30deg);
}

.logo h1,
.logo h2,
.logo h3,
.logo h4,
.logo h5,
.logo h6 {
  margin: 0;
}

.planContainer {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
}

.planContainer:nth-child(1) {
  background: #fffdb1;
}

.planContainer:nth-child(2) {
  background: #fefa7d;
}

.planContainer:nth-child(3) {
  background: #fff700;
}

form input {
  padding: 1em;
  min-width: 200px;
  margin-right: 1em;
  font-size: 1.2em;
}

button {
  padding: 1em;
  background: #14A9F7;
  border: none;
  min-width: 200px;
  font-size: 1.2em;
  color: white;
  font-weight: 400;
  width: fit-content;
  margin: 1em auto;
}

button:hover {
  transform: translate(5px, -5px);
  box-shadow: -3px 3px rgb(223, 14, 42);
}

button:active {
  transform: translate(0, 0);
  box-shadow: 0 0 0 0;
}

img.icon,
{
/* width: 50px; */
}

img.arrows {
  height:60px;
  width:auto;
}

.grid2 img.icon {
  /* width: 50px; */
  margin: 2em auto 0;
  display: block;
}

img.screenshot {
  box-shadow: 5px 5px 6px 1px rgba(0, 0, 0, .2);
  box-shadow: 5px 5px 6px 1px rgba(0, 0, 0, .2);
}

.clickable:hover {
  color:lawngreen;
  cursor: pointer;
}

.highlighter {
    background-image: linear-gradient(90deg, #ff0, #ff0);
    background-repeat: repeat-x;
    background-position: 0 80%;
}

.noMarginTop {
  margin-top:0;
}


/* mobile logic  */
.mobile {
  display: none;
}

/* decorators */
.outlined {
  outline: rgb(20, 20, 20, .8) solid thin;
}

.underlined {
  border-bottom: 1px solid grey;
}

.tinyText {
  font-size: .8em
}

.boldText {
  font-weight: 700
}

.bottomSpacer {
  margin-bottom: 2em
}

.subscript {
  font-size: .8em;
  font-style: italic;
  margin-top: 0;
  color: #14A9F7;
  vertical-align: super;
}

/* alignments */
.textLeftAligned {
  text-align: left
}

.textRightAligned {
  text-align: right
}

.centeredText, .centeredText > p {
  text-align: center;
}



/* media queries */

@media (max-width: 900px) {
  main {
    width: 95%;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  form input,
  form button {
    margin: 0 0 1em 0;
    width: 100%;
  }

  .grid3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid2 {
    grid-template-columns: 1fr 1fr;
  }
  /*   h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 3em;
  }
  h3 {
    font-size: 2em;
  }
  h4 {
    font-size: 1.8em;
  }
  h5 {
    font-size: 1.5em;
  } */

}

@media (max-width: 750px) {

  form input,
  form button {
    min-width: 90vw;
  }



  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2.6em;
  }

  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-size: 1.6em;
  }

  h5 {
    font-size: 1.4em;
  }

  .appSlogan {
    font-size: 2em;
  }
}

@media (max-width: 600px) {
  .mobile {
    display: block
  }

  .desktop {
    display: none
  }
  
  .planContainer {
    align-items: center;
  }


  .grid2 {
    grid-template-columns: 1fr;
    gap: 1em
  }

  .grid2 > p {
    text-align: left;
    margin-bottom: 1em
  }

  .grid2 div:nth-child(1) {
    grid-row: 1;
  }

  .grid2 div:nth-child(2) {
    grid-row: 2;
    /* margin-bottom: 4em; */
  }

  .grid2 div:nth-child(3) {
    grid-row: 4;
    /* margin-bottom: 4em; */
  }

  .grid2 div:nth-child(4) {
    grid-row: 3;
  }

  .grid2 div:nth-child(5) {
    grid-row: 5;
  }

  .grid2 div:nth-child(6) {
    grid-row: 6;
    margin-bottom: 4em;
  }

  .grid2 div:nth-child(7) {
    grid-row: 8;
    margin-bottom: 4em;
  }

  .grid2 div:nth-child(8) {
    grid-row: 7;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.6em;
  }

  h4 {
    font-size: 1.4em;
  }

  h5 {
    font-size: 1.2em;
  }
}

@media (max-width: 500px) {
  .inlineFlexContainer {
    flex-direction: column;
  }
  .grid3 {
    grid-template-columns: 1fr;
  }
}